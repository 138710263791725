import React, { createContext, useState, useContext } from 'react';

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [color, setColor] = useState(null);

  return (
    <ThemeContext.Provider value={{ color, setColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default () => useContext(ThemeContext);
